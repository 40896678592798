import {
  AdjustmentsVerticalIcon,
  BuildingOffice2Icon,
  BuildingOfficeIcon,
  CalendarIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  HomeIcon,
  UserIcon,
  UserPlusIcon,
} from '@heroicons/react/24/outline';
import { GuardEnum } from '@hooks->useRoleGuard';

const navigationConfig = {
  routes: [
    {
      id: 'account',
      path: '/account',
      showInMenu: true,
      segments: ['account'],
      allowInherentWildcard: true,
    },
    {
      id: 'login',
      path: '/login',
      showInMenu: true,
      segments: ['login'],
      constraints: ['guest'],
      allowInherentWildcard: true,
    },
    {
      id: 'forgot-password',
      path: '/forgot-password',
      showInMenu: false,
      segments: ['forgot-password'],
      constraints: [],
      allowInherentWildcard: true,
    },
  ],
  sideNavigation: [
    {
      id: 'dashboard',
      path: '/dashboard',
      label: 'Dashboard',
      showInMenu: false,
      segments: ['dashboard'],
      constraints: [],
      icon: HomeIcon,
      allowInherentWildcard: true,
      routeType: 'main_sidebar',
      guardRole: undefined,
    },
    {
      id: 'cases',
      path: '/cases',
      label: 'Cases',
      showInMenu: true,
      segments: ['cases'],
      constraints: [],
      icon: CalendarIcon,
      allowInherentWildcard: true,
      routeType: 'main_sidebar',
      guardRole: [GuardEnum.CASE, GuardEnum.DISTRIBUTION_PARTNER],
    },
    {
      id: 'conversations',
      path: '/conversations',
      label: 'Conversations',
      showInMenu: true,
      segments: ['conversations'],
      constraints: [],
      icon: ChatBubbleOvalLeftEllipsisIcon,
      allowInherentWildcard: true,
      routeType: 'main_sidebar',
      guardRole: undefined,
    },
    {
      id: 'organization-members',
      path: '/organization-members',
      label: 'Organization members',
      showInMenu: true,
      segments: ['organization-members'],
      constraints: [],
      icon: UserIcon,
      allowInherentWildcard: true,
      routeType: 'main_sidebar',
      guardRole: [GuardEnum.ORGANIZATION_MEMBER, GuardEnum.DISTRIBUTION_PARTNER],
    },
    {
      id: 'organizations',
      path: '/organizations',
      label: 'Organizations',
      showInMenu: true,
      segments: ['organizations'],
      constraints: [],
      icon: BuildingOfficeIcon,
      allowInherentWildcard: true,
      routeType: 'main_sidebar',
      guardRole: [GuardEnum.ORGANIZATION, GuardEnum.DISTRIBUTION_PARTNER],
    },
    {
      id: 'distribution-organizations',
      path: '/distribution-organizations',
      label: 'Distribution org.',
      showInMenu: true,
      segments: ['distribution-organizations'],
      constraints: [],
      icon: BuildingOffice2Icon,
      allowInherentWildcard: true,
      routeType: 'main_sidebar',
      guardRole: GuardEnum.DISTRIBUTION_ORGANIZATION,
    },
    {
      id: 'parameters',
      path: '/parameters',
      label: 'Parameters',
      showInMenu: true,
      segments: ['parameters'],
      constraints: [],
      icon: AdjustmentsVerticalIcon,
      allowInherentWildcard: true,
      routeType: 'main_sidebar',
      guardRole: GuardEnum.PARAMETERS,
    },
    {
      id: 'admin-users',
      path: '/admin-users',
      label: 'Admin users',
      showInMenu: true,
      segments: ['admin-users'],
      constraints: [],
      icon: UserPlusIcon,
      allowInherentWildcard: true,
      routeType: 'main_sidebar',
      guardRole: GuardEnum.ADMIN_USERS,
    },
  ],
} as const;

type Route = Item<typeof navigationConfig.routes>;

type SideNavigationRoute = Item<typeof navigationConfig.sideNavigation>;

export type {
  Route,
  SideNavigationRoute,
};

export default navigationConfig;
