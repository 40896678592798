import useRoleGuard, { RoleGuardType } from '@hooks->useRoleGuard';

type RoleGuardProps = {
  children: React.ReactNode
  type: RoleGuardType | RoleGuardType[] | readonly RoleGuardType[]
  role?: string[]
  distOrg?: string
};

export default function RoleGuard({
  children,
  type,
  role,
  distOrg,
}: RoleGuardProps): JSX.Element | null {
  const { isEnabled } = useRoleGuard(type, role, distOrg);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return isEnabled ? <>{children}</> : null;
}
