import { AdminUserRole } from '@generated->anywhere';
import { useAppSelector } from '@providers->Redux/useReduxHooks';
import { useMemo } from 'react';

export enum GuardEnum {
  ACCOUNT = 'ACCOUNT',
  ONE_TIME_LOGIN = 'ONE_TIME_LOGIN',
  ADMIN_USERS = 'ADMIN_USERS',
  CASE = 'CASE',
  ORGANIZATION_MEMBER = 'ORGANIZATION_MEMBER',
  ORGANIZATION = 'ORGANIZATION',
  DISTRIBUTION_PARTNER = 'DISTRIBUTION_PARTNER',
  DISTRIBUTION_ORGANIZATION = 'DISTRIBUTION_ORGANIZATION',
  DISTRIBUTION_ORGANIZATION_INDEX = 'DISTRIBUTION_ORGANIZATION_INDEX',
  PARAMETERS = 'PARAMETERS',
}

export type RoleGuardType = (typeof GuardEnum)[keyof typeof GuardEnum];

const useRoleGuard = (
  type: RoleGuardType | RoleGuardType[] | readonly RoleGuardType[],
  role?: string[],
  distOrg?: string,
) => {
  const { distribution_organization: adminUserDistOrg, roles: adminUserRoles } = useAppSelector((state) => state.account);

  const types = Array.isArray(type) ? type : [type];

  const isDistOrgOwner = useMemo(() => adminUserDistOrg !== undefined && adminUserDistOrg && adminUserDistOrg.id === distOrg, [adminUserDistOrg, distOrg]);

  const roles = new Set([adminUserRoles, role].flat());

  if (!adminUserRoles) return { isEnabled: false, isLoading: true };

  const isEnabled = types.some((t) => {
    switch (t) {
      case GuardEnum.ACCOUNT:
        return roles.has(AdminUserRole.RoleAccountMasterAdmin);
      case GuardEnum.ADMIN_USERS:
        return roles.has(AdminUserRole.RoleAdminUserMasterAdmin);
      case GuardEnum.CASE:
        return roles.has(AdminUserRole.RoleCaseMasterAdmin);
      case GuardEnum.DISTRIBUTION_ORGANIZATION:
        return roles.has(AdminUserRole.RoleDistributionOrganizationMasterAdmin) || (roles.has(AdminUserRole.RoleDistributionPartnerMaster) && isDistOrgOwner);
      case GuardEnum.DISTRIBUTION_ORGANIZATION_INDEX:
        return roles.has(AdminUserRole.RoleDistributionOrganizationMasterAdmin);
      case GuardEnum.ONE_TIME_LOGIN:
        return roles.has(AdminUserRole.RoleAccountGenerateOneTimeLoginUrl);
      case GuardEnum.ORGANIZATION:
        return roles.has(AdminUserRole.RoleOrganizationMasterAdmin);
      case GuardEnum.ORGANIZATION_MEMBER:
        return roles.has(AdminUserRole.RoleOrganizationMemberMasterAdmin);
      case GuardEnum.PARAMETERS:
        return roles.has(AdminUserRole.RoleParameterMasterAdmin);
      case GuardEnum.DISTRIBUTION_PARTNER:
        return roles.has(AdminUserRole.RoleDistributionPartnerMaster);
      default:
        return false;
    }
  });

  return { isEnabled, isLoading: false };
};

export default useRoleGuard;
