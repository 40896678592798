'use client';

import axios from 'axios';
import useAccountActions from '@application->account/useAdminUser.actions';
import { LogoutOutlined } from '@mui/icons-material';
import { useRouter } from '@root->navigation';

function Logout() {
  const { refresh, push } = useRouter();
  const { logoutAdminUser } = useAccountActions();

  const logout = async () => {
    await axios.post('/api/logout');
    await refresh();
    logoutAdminUser();
    await push('/login');
  };

  return (
    <button
      type="button"
      onClick={logout}
      className="flex w-full text-[14px] items-center gap-x-4 px-6 py-3 text-sm leading-6 text-secondary hover:text-primary hover:bg-grey-5 transition-all duration-300 ease-in"
    >
      <LogoutOutlined />
      <span className="sr-only">Logout</span>
      <span aria-hidden="true">Logout</span>
    </button>
  );
}

export default Logout;
